.offers{
    background-color: var(--primary-color);
    border-radius: 1.875rem 1.875rem 0 0;
    padding:24px 50px 54px 50px;
    margin-top: -30px;
    color: var(--tertiary-color);
    position: sticky;
    height: 155px;
    z-index: 1000;
}

.offerDetails{
    display: flex;
    align-items: center;
    justify-content: center;
}
.offerName{
    font-family: EditorsNote-light;
    color: var(--tertiary-color);
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.offerValue{
    color: var(--secondary-color);
    font-family:EditorsNote-light;
    font-size: 60px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: -2.4px;
    padding: 0 20px;
}

.stickOfferValue{
    color: var(--secondary-color);
    font-family:EditorsNote-light;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 0px;
    padding-left: 20px;
}

.stickDesc{
    font-size: 20px;
    letter-spacing: normal;
    text-transform: uppercase;
    font-family: worksans-light;
}

.offerDesc{
    font-family: worksans-light;
    color: var(--secondary-color);
    text-transform: uppercase;
    letter-spacing: 1.9px;
}

.stickyOfferDesc{
    font-family: worksans-light;
    color: var(--secondary-color);
    text-transform: uppercase;
    letter-spacing: 1.9px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 12px;
    padding-top: 4px;
}

.desc1{
    font-family: worksans-regular;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.1125rem;
    text-transform: uppercase;
}

.desc2{
    font-family: worksans-regular;
    color: var(--text-light);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.035rem;
    text-transform: uppercase;
    padding-top: 0.25rem;
}

.stickyOfferValue1{
    font-size: 2rem;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.08rem;
}

.stickDesc1{
    font-family: worksans-regular;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.0875rem;
    text-transform: uppercase;
}

.stickDesc2{
    font-family: worksans-regular;
    color: var(--text-light);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.035rem;
    text-transform: uppercase;
    padding-left: 0px;
}

.sticky{
    border-radius: 0;
    padding: 0px;
    z-index: 11;
    height: 3rem;
    top: 104px;
}

.stickOfferName{
    font-size: 14px;
}

.stickOfferDetails{
    display: flex;
    width: 100%;
}



@media screen and (max-width: 960px) {
    .offers{
        padding: 1.69rem 1rem 2rem;
        height: 156px;
    }
.offerDetails{
    flex-flow: column;
}
.offerValue{
    display: flex;
    gap:20px;
    align-items: center;
    padding: 0.5rem 0 .5rem 0;
}
.offerValue1{
    font-size: 48px;
    text-align:right
}
.offerValue .desc{
    font-family: worksans-medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1rem; /* 114.286% */
    letter-spacing: 0.0875rem;
    text-transform: uppercase;
}
.offerDesc{
    font-family: worksans-medium;
    color: var(--secondary-color);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.0525rem;
    text-transform: uppercase;
}
.sticky{
    border-radius: 0;
    padding: 0rem;
    z-index: 1000;
    height: 3.75rem;
}
.stickyOfferDetails{
    display: flex;
    flex-flow: column;
    padding-left: 1rem;
}
.stickDesc{
    font-size: 0.68rem;
    padding-left: 0.6rem;
    padding-top: 2px;
}
.stickOfferDetails{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
}
.stickOfferValue{
    display: flex;
    align-items: center;
    letter-spacing: -0.06rem;
    font-size: 1.6rem;
    padding-left: 0;
}
.stickDesc2{
    font-size: 0.6rem;
}
.stickyOfferDesc{
    padding-left: 0px;
}
.sticky{
    top: 90px;
}
}

@media screen and (max-width: 440px) {
    .offers{
        height: 140px;
    }
    .offerValue1{
        font-size: 30px;
        text-align:right
    }
    .offerValue .desc{
        padding-top: 0px;
        font-size: 12px;
    }
    .offerValue{
        align-items: center;
    }
    .sticky{
        height: 72px;
    }
    .stickOfferDetails{
        display: flex;
        flex-flow: column;
    }
}