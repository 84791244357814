.designerMessage{
    background-color: var(--bg-darkBlue);
    border-radius: 1.875rem 1.875rem 0 0;
    padding: 80px 50px 120px 50px;
    margin-top: -30px;
    color: var(--white);
}
.messageSection{
    display: flex;
    align-items: stretch;
    justify-content: center;
    gap: 40px;
}
.title{
    letter-spacing: -1.32px;
    font-family: EditorsNote-Thin;
    font-size: 66px;
    font-weight: 100;
}
.title span{
    font-family: EditorsNote-ThinItalic;
    font-style: italic;
    color: var(--secondary-color);
    letter-spacing: normal;
    font-weight: 100;
}

.message{
    font-family: EditorsNote-Thin;
    font-size: 48px;
    color: var(--secondary-color);
    font-weight: 100;
    line-height: 54px;
    letter-spacing: -0.96px;
    border-left: 2px solid var(--secondary-color);
    padding:00px 0 00px 50px;
    margin: 50px 0px;
}
.name{
    font-family: EditorsNote-Thin;
    color: var(--white);
    font-size: 2.5rem;
    font-style: italic;
    font-weight: 200;
    line-height: normal;
}
.description{
    font-family: worksans-regular;
    font-weight: 400;
    line-height: 150%; 
    letter-spacing: -0.352px;
    font-size: 16px;
    padding: 10px 0px
}

.rightSection{
    position: relative;
}

.rightSection img{
    object-fit: cover;
    color: transparent;
    transform: scale(1.3);
    height: 100%;
}

@media screen and (min-width: 960px) {
.desktopImageSize{
    height: 100%;
    overflow: hidden;
    border-radius: 30px;
}
}

@media screen and (max-width: 960px) {
    .designerMessage{
        padding: 40px 16px;
    }
    .messageSection {
        display: flex;
        gap: 2rem;
        flex-flow: column-reverse;
        margin-bottom: 80px;
    }
    .title{
        text-align: center;
        padding-bottom: 33px;
        font-size: 2.125rem;
        font-style: normal;
        font-weight: 200;
        line-height: normal;
        letter-spacing: normal
    }
    .message{
        padding: 0px 1.375rem;
        margin: -200px 0px 10px;
        text-align: center;
        border-left: 0px;
        position: absolute;
        bottom: 0px;
        z-index: 1;
        font-size: 2.2rem;
        line-height: 2.375rem;
    }
    .rightSection img{
        width: 100%;
        object-position: 16px -14%;
        height: revert-layer;
        transform: scale(1.8);
        height: 400px;
    }

    .leftSection{
        padding: 0px 30px;
    }
    .gradientEffect{
        position: relative;
        border-radius: 20px 20px 0 0;
        overflow: hidden;
    }
    .gradientEffect::before{
        content: "";
        background: linear-gradient(0deg, rgba(17,21,47,1) 0%, rgba(255,255,255,0) 100%);
        width: 100%;
        text-align: center;
        position: absolute;
        bottom: 0px;
        z-index: 1;
        height: 300px;
    }
    .name{
        font-size: 1.5rem;
        font-weight: 200;
        text-align: center;
        padding-bottom: 1rem;
    }
    .name span{
        font-weight: 300;
    }
}