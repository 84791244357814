.banners{
    background-color: rgba(0, 0, 0, 0.30);
    display: flex;
    align-items: center;
    margin-top: 80px;
    height: calc(100vh - 160px);
}

.positionReleative{
    position: relative;
}

.darkBg{
    background: #000;
    opacity: 0.3;
    position: absolute;
    top:0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 100;
}

.bannerDesktopView{
    width: 100%;
    display: flex;
}

.bannerDesktopView img{
    width: 33.3%;
    object-position: top center;
    object-fit: cover;
}

.video {
    width: 100%;
    height: auto;
  }
.info {
    font-family: EditorsNote-Thin;
    font-size: 4.5rem;
    color: var(--white);
    text-align: center;
    font-weight: 100;
    position: absolute;
    bottom: 1rem;
    line-height: 4.875rem; /* 108.333% */
    letter-spacing: -0.09rem;
    max-width: 1000px;
    text-align: center;
    width: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 101;
}

.info span{
    font-family: EditorsNote-Italic;
}

.bannerMobileView{
    width: 100%;
    object-fit: cover;
    object-position: top;
    height: 100%;
}

@media screen and (max-width: 960px) {

    .banners{
        height: calc(100vh - 205px);
    }
    .bannerDesktopView{
        display: none;
    }
.info{
    font-size: 2.625rem;
    padding: 0px 10px;
    position: absolute;
    bottom: 5rem;
    font-weight: 100;
    letter-spacing: -0.09rem;
    line-height: 3rem;
z-index: 100;}
}

.bannerImageAlign{
    width: 100%;
    height: 100%;
    img{
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}

.bannerImageAlignLastImage{
    width: 100%;
    height: 100%;
    img{
        object-fit: cover;
        width: 100%;
        height: 100%;
        @media screen and (max-width: 600px) {
        object-position: 80%;
        }
    }
}