
.swiper {
    margin-top: 1.6rem;
  }
  
.swiperList {
    background: var(--white);
    display: flex;
    flex-flow: column;
    border-radius: 10px;
    padding: 20px;
    align-items: flex-start;
  }
  .rating{
    padding-bottom: .80rem;
  }
  .feedback{
    display: flex;
    font-family: worksans-regular;
    font-size: 16px;
    line-height: 26px;
    text-align: left;
  }
.authorDetails{
    display: flex;
    flex-flow: column;
    padding-top: 15px;
    align-items: flex-start;

}
.authorDetails .name{
    font-family: 'EditorsNote-Regular';
    font-style: normal;
    font-style: 16px;
}
.authorDetails p{
    font-family: worksans-italic;
}
.prev {
    left: 63% !important;
    top: 40% !important;
    transform: rotate(90deg);
    transform-origin: left center;
  }
.next {
    left: 63% !important;
    bottom: 49% !important;
    transform: rotate(90deg);
    transform-origin: left center;
  }
  .city{
    font-family: 'EditorsNote-Regular';
    font-style: normal;
    font-size: 14px;
  }