.footer{
    background-color: var(--white);
    border-radius: 1.875rem 1.875rem 0 0;
    padding: 3em 50px 3rem 50px;
    margin-top: -30px;
}
.info{
    font-family: worksans-regular;
    font-size: 5.5rem;
    text-align: center;
    opacity: .10;
    font-weight: 400;
    letter-spacing: -0.55rem;
}
.info span {
    font-family: EditorsNote-ThinItalic;
    letter-spacing:normal;
    font-size: 5.9375rem;
}

.contactSection{
    border: 1px solid #DCDDE9;
    border-left: 0px;
    border-right: 0px;
    margin-top: 20px;
    padding: 1.5rem 0px;
    display: flex;
    justify-content: center;
}

.contactDetails{
    align-items: center;
    display: flex;
    justify-content: space-evenly;
}

.contacts, .socialMedias{
    display: flex;
}
.contacts{
    flex-grow: 1;
    justify-content: space-evenly;
}
.socialMedias{
    flex-shrink: 5;
}

.logo{
    display: flex;
    justify-content: center;
}
.logo img{
    width: 130px;
    height: 62px;
}
.socialMediaIcons{
    padding: 0 0.8rem;
}

.socialMediaIcons a{
    display: inline-block;
}

.socialMediaIcons img{
    width: 40px;
    height: 40px;
}

.labels{
    font-family: worksans-regular;
    padding-right: 2.4rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem;
    letter-spacing: -0.04rem;
}
.labels span{
    font-weight: 600;
}

.emailIcon{
    background-image: url(../../assets/icon/mailicon.png);
    background-repeat: no-repeat;
    background-size: 16px 15px;
    padding-left: 26px;
    padding-top: 0px;
    background-position: left;
}

.callIcon{
    background-image: url(../../assets/icon/call.png);
    background-repeat: no-repeat;
    background-size: 18px 18px;
    padding-left: 26px;
    padding-top: 0px;
    background-position: left;
}

.locationIcon{
    background-image: url(../../assets/icon/mark.png);
    background-repeat: no-repeat;
    background-size: 20px 20px;
    padding-left: 26px;
    padding-top: 0px;
    background-position: left;
}

.copyright{
    font-family: worksans-regular;
    text-align: center;
    padding-top: 15px;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem; /* 128.571% */
    letter-spacing: -0.035rem;
}

@media screen and (max-width: 960px) {
    .footer{
        padding: 2.31rem 1rem 2rem;
    }
.info{
    font-size: 48px;
    letter-spacing: -5px;
    margin-top: 0;
}
.info span{
    margin-top: -24px;
    font-size: 64px;
    letter-spacing: -0.28rem;
}
.logo img{
    width: 90px;
    height: 42px;
}

.contacts, .contactDetails{
    flex-flow: column;
    align-items: center;
}
.socialMediaIcons img{
    width: 48px;
    height: 48px;
    margin: 5px;
}
.contacts{
    padding-top: 20px;
}
.labels{
    margin-bottom: 26px;
    padding-right: 0px;
}
}