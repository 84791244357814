.background{
    background-color: var(--bg-light);
    border-radius: 1.875rem 1.875rem 0 0;
    padding: 5rem 5rem 7.5rem 5rem;
    margin-top: -2.5rem;
    z-index: 1;
    transition: margin cubic-bezier(0.075, 0.82, 0.165, 1);
}

@keyframes move {
    0% {z-index:1;opacity:1}
    50% {opacity:0}
    100% { z-index:-1;opacity:1}
  }
  @keyframes move {
    0% {z-index:1;opacity:1}
    50% {opacity:0}
    100% {z-index:-1;opacity:1}
  }

.heading{
    padding-bottom: 3rem;
}
.listview{
    display: flex;
    flex-flow: row;
    gap: 2rem;
}
.list{
    position: relative;
}

.title{
    color: var(--primary-color);
    font-family: EditorsNote-Thin;
    font-size: 2rem;
    position: relative;
    flex-grow: 1;
    letter-spacing: -0.02rem;
}

.content{
    font-family: worksans-regular;
    color: var(--text-dark);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.375rem;
    letter-spacing: -0.04rem;
}

.booknow{
    display: flex;
    justify-content: center;
    padding: 3.6rem 0 0;
    margin-bottom: 0px;
}

.booknow button{
    font-size: 20px;
    padding: 1rem 3rem;
}
.listOptions{
display: flex;
padding-bottom: 0.63rem;
}


.listBorderStyle{
    background-image: url('../../assets/icon/arrow.png');
    flex-shrink: 1;
    min-width: 200px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto 20px;
}


.btnWidth{
    min-width: 300px;
    display: flex;
    justify-content: center;
}


@media screen and (min-width: 960px) {
    .listBorderStyle.nobackground{
        background-image: none;
    }
}

@media screen and (max-width: 960px) {
    .background{
        padding: 3rem 2rem
    }
    .listview{
        padding: 2rem 1rem;
        display: flex;
        flex-flow: column;
        gap: 1rem;
    }
    .list{
        display: flex;
        flex-flow: column;
        align-items: center;
    }
    .heading{
        font-size: 2.125rem;
        letter-spacing: -0.06215rem;
        padding-bottom: 0;
        position: relative;
        font-weight: 100;
    }
    .content{
        text-align: center;
        color: var(--text-dark);
    }

    .booknow{
        padding: 1.5rem 0 0;
        margin-bottom: 3rem;
    }
    .listOptions{
        display: flex;
        flex-flow: column-reverse;
        width: 100%;
    }
    .title{
        font-size: 1.75rem;
        font-family: EditorsNote-Extralight;
        text-align: center;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: -0.01625rem;
    }
    .listBorderStyle{
        background-image: url('../../assets/icon/arrow-mobile.png');
        min-width: 200px;
        background-repeat: no-repeat;
        height: 60px;
        background-position: center center;
        background-size: 32px auto;
        margin-bottom: 1rem;
    }
    .btnWidth{
        height: 56px;
    }
}