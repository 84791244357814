.header{
    display: flex;
    padding: 1rem;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}
.headerbg{
    background-color: var(--white);
    width: 100%;
    position: fixed;
    top: 0px;
    z-index: 200;
}
.logo{
    width: 110px;
    height: 52px;
}

.leftSection{
    flex-flow: column;
}
 .rightSection{
    display: flex;
}
.leftSection .text{
    font-family: EditorsNote-Regular;
    align-content: center;
    font-weight: 400;
    padding: 0px 0px;
    font-size: 0.9125rem;
    line-height: 1rem; /* 169.231% */
    color: var(--text-black);
}
.rightSection .text{
    font-weight: 400;
    align-content: center;
    font-family: worksans-regular;
    margin-right: 15px;
    text-align: right;
}
.rightSection .text span{
    font-weight: 500;
}

.infomessage{
    width: 100%;
    background-color: #EBECEE;
    padding: 10px;
    text-align: center;
    font-family: worksans-regular;
    font-size: 13px;
}


@media screen and (max-width: 960px) {
    .header{
        display: grid;
        grid-template-columns: 60px auto 180px;
        justify-content: inherit;
        padding: 1rem 0;
    }
    .leftSection{
        flex-flow: column;
        margin-left: 0rem;
    }
    .leftSection .text{
        padding: 0px 0px;
    }
    .leftSection span {
        font-weight: 500;
    }

    .logo{
        width: 83px;
        height: 39px;
    }
    .rightSection{
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        padding-right: 1rem;
    }
    .rightSection a{
        padding: 0.5rem 0.75rem;
    }

    .rightSection .header-btn{
        padding-left: 0.38rem;
    }
}
