.works{
    background-color: var(--white);
    border-radius: 1.875rem 1.875rem 0 0;
    padding: 80px 50px 140px 50px;
    margin-top: -30px;
    color: var(--text-dark);
}

.title{
    font-family: EditorsNote-Regular;
    font-size: 24px;
    text-align: center;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: -0.06rem;
}


.gallery {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: repeat(18, 5vw);
    grid-gap: 3.5rem; 
  }
  
  .galleryImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    object-position: center;
    border-radius: 15px;
  }


/* col 1 */
.galleryItem1 {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 7;
  }
  .galleryItem4 {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 7;
    grid-row-end: 12;
  }
  .galleryItem7 {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 12;
    grid-row-end: 19;
  }

/* col 2 */
  .galleryItem2 {
    grid-column-start: 4;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 5;
  }

  .galleryItem5 {
    grid-column-start: 4;
    grid-column-end: 7;
    grid-row-start: 5;
    grid-row-end: 14;
  }
  .galleryItem8 {
    grid-column-start: 4;
    grid-column-end: 7;
    grid-row-start: 14;
    grid-row-end: 19;
  }
  
/* col 3 */

  .galleryItem3 {
    grid-column-start: 7;
    grid-column-end: 10;
    grid-row-start: 1;
    grid-row-end: 6;
  }
  .galleryItem6 {
    grid-column-start: 7;
    grid-column-end: 10;
    grid-row-start: 6;
    grid-row-end:10;
  }
  .galleryItem9 {
    grid-column-start: 7;
    grid-column-end: 10;
    grid-row-start: 10;
    grid-row-end: 19;
  }


  .headingText{
    padding-bottom: 3rem;
  }


@media screen and (max-width: 960px) {

  .works{
    padding: 40px 20px 140px 20px;
  }
  .gallery {
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      grid-template-rows: repeat(34, 5vw);
      gap: 2.6rem 1.5rem;
    }
/* col 1 */
.galleryItem1 {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 8;
  }
  
  .galleryItem3 {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 8;
    grid-row-end: 15;
  }
  .galleryItem5 {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 15;
    grid-row-end: 25;
  }
  .galleryItem8 {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 25;
    grid-row-end:30;
  }


  .galleryItem2 {
    grid-column-start: 5;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 6;
  }
  .galleryItem4 {
    grid-column-start: 5;
    grid-column-end: 9;
    grid-row-start: 6;
    grid-row-end: 12;
  }
  .galleryItem6 {
    grid-column-start: 5;
    grid-column-end: 9;
    grid-row-start: 12;
    grid-row-end: 17;
  }
  .galleryItem7 {
    grid-column-start: 5;
    grid-column-end: 9;
    grid-row-start: 17;
    grid-row-end: 26;
  }

  .galleryItem9 {
    grid-column-start: 5;
    grid-column-end: 9;
    grid-row-start: 26;
    grid-row-end: 35;
  }

  .headingText{
    padding-bottom: 1.5rem;
  }
}
