.feedbacks{
    background-color: var(--bg-orange);
    border-radius: 1.875rem 1.875rem 0 0;
    padding: 80px 50px 120px 50px;
    margin-top: -30px;
}
.feedbackTitle{
    display: flex;
    justify-content: center;
    align-items: center;
}
.quationLeft img, .quationRight img{
    width: 156px;
    height: 136px;
}
.quationBeYouLogo{
    margin: 45px 3.4rem 0;
}
.quationBeYouLogo img{
    width: 200px;
    height: 200px;
}
.quationLeft img, .quationBeYouLogo img, .quationRight img{
    object-fit: cover;
}


@media screen and (max-width: 960px) {
    .feedbacks{
     padding: 60px 20px 80px;   
    }
    .quationLeft img, .quationRight img{
        width: 58px;
        height: 44px;
        object-fit: contain;
    }
    .quationBeYouLogo{
        margin: 0px 15px;
    }
    .quationBeYouLogo img{
        width: 172px;
        height: 172px;
    }
}