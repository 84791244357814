:root {

  --primary-color: #8C0034;
  --secondary-color: #E8980C;

  --tertiary-color: #ECDBCA;

  --white: #fff;

  --text-dark: #323A4F;
  --text-primary: #8C0034;
  --text-light: #F4E7DE;
  --text-black: #0F1334;

  --bg-light: #F4E7DE;
  --bg-dark:#0F1334;
  --bg-gray: #F4F5F9;
  --bg-orange: #E9D5C7;
  --bg-yellow: #F6EFE7;
  --bg-darkBlue: #11152F;

  --border-dark:#323A4F;

  --border-black:#0F1334;

  --xl-border-radius: 40px
}

@font-face {
  font-family: EditorsNote-Regular;
  src: url("./assets/fonts/editors-note/EditorsNote-Regular.otf");
}
@font-face {
  font-family: EditorsNote-Bold;
  src: url("./assets/fonts/editors-note/EditorsNote-Bold.otf");
}
@font-face {
  font-family: EditorsNote-light;
  src: url("./assets/fonts/editors-note/EditorsNote-Light.otf");
}
@font-face {
  font-family: EditorsNote-Italic;
  src: url("./assets/fonts/editors-note/EditorsNote-Italic.otf");
}


@font-face {
  font-family: EditorsNote-Thin;
  src: url("./assets/fonts/editors-note/EditorsNote-Thin.otf");
}

@font-face {
  font-family: EditorsNote-ThinItalic;
  src: url("./assets/fonts/editors-note/EditorsNote-ThinItalic.otf");
}

@font-face {
  font-family: EditorsNote-Extralight;
  src: url("./assets/fonts/editors-note/EditorsNote-Extralight.otf");
}

@font-face {
  font-family: worksans-regular;
  src: url("./assets/fonts/work-sans/WorkSans-Regular.ttf");
}
@font-face {
  font-family: worksans-bold;
  src: url("./assets/fonts/work-sans/WorkSans-Bold.ttf");
}
@font-face {
  font-family: worksans-light;
  src: url("./assets/fonts/work-sans/WorkSans-Light.ttf");
}
@font-face {
  font-family: worksans-thin;
  src: url("./assets/fonts/work-sans/WorkSans-Thin.ttf");
}
@font-face {
  font-family: worksans-medium;
  src: url("./assets/fonts/work-sans/WorkSans-Medium.ttf");
}

@font-face {
  font-family: worksans-semiBold;
  src: url("./assets/fonts/work-sans/WorkSans-SemiBold.ttf");
}




* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-padding: 100px;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  font-family: EditorsNote-Regular, Times, serif;
}
html{
  scroll-behavior: smooth;
}

body{
  height: 100vh;
}

.container{
  max-width: 1280px;
  margin: 0 auto;
}

.btn-light{
  background-color: transparent;
  border: 1px solid var(--border-dark);
  border-radius: var(--xl-border-radius);
  padding: 12px 24px;
  display: flex;
  align-items: center;
  font-size: 20px;
  color: var(--border-dark);
  font-family: worksans-regular;
}
.btn-icon{
  width: 1.5rem;
  height: 1.5rem;
}
.btn-light:hover{
  background-color: var(--bg-dark);
  color: var(--white);
}
.btn-light span{
  padding-left: 12px;
  font-weight: 600;
  font-size: 16px;
}


.btn-dark{
  background-color: var(--bg-dark);
  border: 1px solid var(--border-dark);
  border-radius: var(--xl-border-radius);
  padding: 12px 24px;
  display: flex;
  align-items: center;
  color: #fff;
  font-family: worksans-regular;

}
.btn-dark:hover{
  background-color: #1D2052;
}
.btn-dark span{
  padding-left: 12px;
  font-weight: 600;
  font-size: 16px;
}


.heading{
  font-family: EditorsNote-Thin;
  font-size: 3rem;
  color: var(--text-dark);
  font-style: normal;
  font-weight: 100;
  line-height: normal;
  letter-spacing: -0.06rem;
  text-align: center;
}
.heading span {
  font-family: EditorsNote-ThinItalic;
  color: var(--text-primary);
  font-style: italic;
  font-weight: 200;
  line-height: normal;
  letter-spacing: -0.06rem;
}

.zIndex1{
  z-index: 1;
}


.swiper-next {
  position: relative;
  display: block;
  padding: 0;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  background: transparent;
  border: 0px;
  width: 35px;
  height: 35px;
  cursor: pointer;
}
.swiper-next:after, .swiper-next:before {
  content: "";
  display: block;
  position: absolute;
  transition: all 0.5s ease-in-out;
}
.swiper-next:before {
  top: 50%;
  width: 20px;
  height: 20px;
  transform-origin: 50% 50%;
  transform: translate(0, -0.5em) rotate(45deg);
  box-shadow: inset -0.05em 0.05em 0 0 var(--border-black);
  border-radius: 0 0em 0 0;
}
.swiper-next:after {
  top: 16px;
  width: 2rem;
  height: 0.05em;
  background-color: var(--border-black);
  transform: translate(0em, -0.15em);
  right: 12px;
}

.swiper-next-disabled:before{
  opacity: 0.2;
}
.swiper-next-disabled:after{
  opacity: 0.2;
}

.swiper-prev {
  position: relative;
  display: block;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  background: transparent;
  border: 0px;
  width: 35px;
  height: 35px;
  cursor: pointer;
}
.swiper-prev:after, .swiper-prev:before {
  content: "";
  display: block;
  position: absolute;
  transition: all 0.5s ease-in-out;
}
.swiper-prev:before {
  top: 50%;
  width: 20px;
  height: 20px;
  transform-origin: 50% 50%;
  transform: translate(0, -0.5em) rotate(45deg);
  box-shadow: inset 0.05em -0.05em 0 0 var(--border-black);
  border-radius: 0 0 0 0;
}
.swiper-prev:after {
  top: 16px;
  width: 2rem;
  height: 0.05em;
  transform: translate(0em, -0.15em);
  background-color:var(--border-black);
  left: -3px;
}

.swiper-prev-disabled:before{
  opacity: 0.2;
}
.swiper-prev-disabled:after{
  opacity: 0.2;
}

.swiper {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  height: calc((100% - 30px) / 2) !important;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}


.navigationlink{
  display: flex;
  margin-top: 3.5rem;
  justify-content: center;
  gap: 3.5rem;
}


.icon {
 fill: var(--bg-dark)
}
.icon:hover,
.icon:focus {
  fill: var(--white)
}

.d-flex{
  display: flex;
}
.w-100{
  width: 100%;
}
.h-100{
  height: 100%;
}

.p-0{
  padding: 0 !important;
}

a{
  text-decoration: none;
  color: inherit;
}


.beyou{
  font-family: EditorsNote-light;
  
  font-weight: 100;
}
.beyou span{
  font-family: EditorsNote-italic;
  font-style: italic;
}

.galleryImg figcaption{
  display: none;
  padding: 10px 0px;
  text-align: center;
  font-size: 1.2rem;
  font-family: worksans-regular;
  line-height: normal;
  letter-spacing: -0.0375rem;
}
.product{
  overflow: hidden;
  border-radius: 15px;
  height: 100%;
}
.product img{
  width: 100%;
  transition: transform 0.4s ease;
  transform-origin: 50% 50%;
  overflow: hidden;
}

.product img:hover{
  transform: scale(1.2);
    width: 100%;
}

.sticky{
  border-radius: 0;
  padding: 0px;
  z-index: 11;
  height: 3rem;
}

.sticky .offerName{
  font-size: 1.2rem;   
}
.stickOfferValue{
  font-size: 0.8rem;
}

.logoAnimate .beyouText {
  animation: spin 20s cubic-bezier(.3, .3, .3, .3) infinite;
  transform-origin: 50% 50%;
  transform-box: fill-box;
 }

 .bm-cross-button > span{
  position: absolute;
  top: 5px;
  right: 14px;
 }

 
 /* .logoAnimate .beyouText {
  animation: spin 20s infinite linear;
  transform: translate(50%, 50%);
  transform-origin: 50% 50%;
  font-family: EditorsNote-Regular;
  font-size: 12px;
  letter-spacing: 2px;
  word-spacing: 15px;
  color: #A5642C;
 } */
 @keyframes spin{
  50% { transform: rotate(180deg); }
  100% { transform: rotate(360deg); }
 }

 /* @keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
} */


.callicon{
  background-image: url("./assets/icon/callicon-dark.png");
  width: 20px;
  height: 20px;
  background-size: 20px 20px;
}

.btn-light:hover span.callicon{
  background-image: url("./assets/icon/callicon-light.png");
  width: 20px;
  height: 20px;
  background-size: 20px 20px;
}


.view-ondesktop{
  display: block;
}
.view-onmobile{
display: none;
}

.b-inline{
  display: inline-block;
}

.header-btn{
  letter-spacing: normal;
}

.star-svg{
  margin: 0px 3px;
}

.floating-icon{
  position: fixed;
  z-index: 10000;
  right: 2rem;
  bottom: calc(100vh - 90vh);

}

.floating-icon a{
  display: inline-block;
}


.floating-icon img{
  object-fit: contain;
  width: 3rem;
  height: 3rem;
}

@media screen and (max-width: 960px) {
  .view-ondesktop{
      display: none;
  }
  .view-onmobile{
    display: block;
  }

  .heading{
    font-family: EditorsNote-Thin;
    font-size: 2.125rem;
    color: var(--text-dark);
    text-align: center;
    font-weight: 100;
    line-height: 2.375rem;
    letter-spacing: -.06215rem;
  }
  .heading span {
    font-family: EditorsNote-ThinItalic;
    color: var(--text-primary);
    font-weight: 200;
  }
  .galleryImg figcaption{
    padding: 8px 0px 0;
    font-size: 0.87rem;
  }
  .header-btn{
    letter-spacing: -0.04rem;
  }
  .navigationlink{
    margin-top: 2.5rem;
  }
  .logoAnimate{
    width: 173px;
  }
  .floating-icon{
    position: fixed;
    z-index: 10000;
    right: 1rem;
    bottom: 120px;
  }
}




#page-wrap {
  text-align: center;
  overflow: auto;
}

/* Individual item */
.bm-item {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
  color: white;
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 16px;
  top: 0px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
  background: #bdc3c7;
  border-radius: 30px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #fff;
  color: #fff;
}

/* General sidebar styles */
.bm-menu {
  font-size: 1.15em;
  background: rgba(245, 231, 222, 1);

}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  background: rgba(245, 231, 222, 1);
  backdrop-filter: blur(2px);
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  top: 0;
}

.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top:0
}

.mobile-menu__logo{
  background-color: #F5E7DE;
  display: flex;
  flex-flow: column;
  padding: 3rem 0;
  border-bottom: .5px solid #BB9F8F;
  align-items: center;
}

.mobile-menu__logo p{
  color: var(--text-black);
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem; 
}

.mobile-menu__links{
  padding: 0 1rem;
  background-color: #F5E7DE;
}

.menu-item{
  color: #8C0034;
  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 300;
  line-height: 4.875rem;
  display: block;
  background: none;
  border: 0;
  border-bottom: .5px solid #BB9F8F;
  width: 100%;
  cursor: pointer;
  font-family: EditorsNote-Regular;
}


@media screen and (max-width: 960px) {
  .hamburger-menu{
    position: relative;
  }
  .bm-cross-button button{
    background-color: #323A4F;
    border-radius: 15px;
  }
}

.hamburger-menu{
  width: 26px;
  height: 20px;
}

.social-medias{
  display: flex;
  justify-content: center;
  padding: 1rem 0;
}

.social-media-icons{
  padding: 0 0.8rem;
}

.social-media-icons a{
  display: inline-block;
}

.social-media-icons img{
  width: 40px;
  height: 40px;
}


.classname{
  -moz-transition: all .51s;
-o-transition: all .51s;
-webkit-transition: all .51s;
transition: all .51s;
}

.backwards-animation{
  animation-fill-mode:backwards;
}

.forwards-animation{
  animation-fill-mode:forwards;
}

.d-none{
  display: none;
}

.d-block{
  display: block;
}

.swiper-slide{
  height: 100% !important;
}
