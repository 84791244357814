.choices{
    background-color: var(--white);
    border-radius: 1.875rem 1.875rem 0 0;
    padding: 5rem;
    padding-bottom: 7rem;
    margin-top: -30px;
}
.choicelist{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 30px 40px;
}

.list{
    padding: 16px 10px;
    border-radius: 10px;
    min-width: 22%;
    display: flex;
    flex-flow: column;
    align-items: center;
}
.listTitle{
    font-family: EditorsNote-Regular;
    color: var(--text-dark);
    text-align: center;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: -0.03rem;
}
.listLabel{
    font-family: worksans-light;
    font-weight: 300;
    font-size: 14px;
    padding-top: 0.25rem;
    font-style: italic;
}
.listLabel span {
    font-family: worksans-light;
    font-weight: 400;
}

.heading{
   padding-bottom: 3rem; 
}

@media screen and (min-width: 960px) {

.hideSlideNavigation{
    display: none;
}
}
@media screen and (max-width: 960px) {
    .choices{
        padding: 3rem 0rem 5rem 1.5rem;
    }
    .heading{
        padding-bottom: 2rem; 
     }
     
    .listview{
        padding: 20px;
        display: flex;
        flex-flow:column
    }
    .list{
        display: flex;
        flex-flow: column;
        align-items: center;
    }
}